'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useGetAllArticlesQuery } from '@/RTK/services/articleApi';
import { articles, latestSearches } from '@/utils/content';
import {
  ChevronLeft,
  ChevronRight,
  Lightbulb,
  PencilRuler,
  Plus,
  SquarePen,
} from 'lucide-react';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import Slider from 'react-slick';

import { Button } from '@/components/ui/button';

import {
  article1,
  article2,
  article3,
  article4,
  article5,
  article6,
  commentimage,
  pen,
} from '../../../public/images';
import { interestLabels } from '../../utils/content';
import Loader from '../../utils/Loader';
import HomeNavbar from './(dashboard)/components/HomeNavbar';
import ExploreArticleCard from './(dashboard)/components/ExploreArticleCard';

const HomePage = () => {
  const [interests, setInterests] = useState([
    'اخترنا لك',
    'كتّاب تتابعهم',
    'علم النفس',
    'الفلسفة',
    'علوم',
    'التقنية',
    'طب',
    'فن',
    'تاريخ',
  ]);
  const [isSticky, setIsSticky] = useState(false); // Track sticky state
  const sliderRef = useRef(null);
  const router = useRouter();
  const [selected, setSelected] = useState('');
  const [openTopicModal, setOpenTopicModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useGetAllArticlesQuery();

  // Scroll event handler
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const stickyThreshold = 130; // Adjust this value as needed

    if (scrollTop > stickyThreshold) {
      setIsSticky(true); // Make slider sticky
    } else {
      setIsSticky(false); // Reset slider to normal
    }
  };

  const customStyles = {
    content: {
      right: '10%',
      bottom: '10%',
      top: 'auto',
      left: 'auto',
      marginRight: '-20%',
      width: '90%',
      maxWidth: '170px',
      maxHeight: '230px',
      padding: '10px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'none',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
  };
  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    swipeToSlide: true,
    draggable: true,
    rtl: true, // This enables right-to-left sliding
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 380,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const handleTopicToggle = (topic) => {
    setInterests((prevInterests) => {
      // If topic is already in interests, remove it
      if (prevInterests.includes(topic)) {
        return prevInterests.filter((interest) => interest !== topic);
      }
      // If topic is not in interests, add it after the first two items
      const firstTwo = prevInterests.slice(0, 2);
      const remaining = prevInterests.slice(2);
      return [...firstTwo, topic, ...remaining];
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {isLoading && (
        <div className="h-[70vh] w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <main className="flex flex-col w-full">
          <HomeNavbar />
          <div className="flex lg:flex-row flex-col w-full">
            <div
              className={`lg:p-8 md:p-5 p-3 lg:border-l lg:w-2/3 flex flex-col gap-3`}
            >
              <div
                className={`${isSticky ? 'fixed top-0 w-fit z-10 border-b bg-white  ' : ''} transition-all duration-300`}
                style={{ width: isSticky ? '100%' : 'auto' }}
              >
                <div className="flex items-center flex-row w-full  relative  md:gap-4">
                  {/* <span
                onClick={() => setOpenTopicModal(true)}
                className="w-8 h-8 rounded-full hover:bg-gray-100 flex items-center justify-center"  >
                <Plus
                  width={20}
                  height={20}
                  className="cursor-pointer hover:text-black text-[#6B6B6B]"
                />
              </span> */}
                  {/* <div
                    className={`  flex gap-2 w-full items-center py-2  rounded-md`}
                  >
                    <div
                      onClick={next}
                      className="w-8 h-8 rounded-full cursor-pointer hover:bg-gray-100 flex items-center justify-center"
                    >
                      <ChevronRight
                        width={23}
                        height={23}
                        className="cursor-pointer hover:text-black text-[#6B6B6B]"
                      />
                    </div>
                    <div className="lg:w-[460px] md:w-[580px]  sm:w-[400px]  w-[235px] ">
                      <Slider ref={sliderRef} {...settings}>
                        {interestLabels.map((interest) => (
                          <span
                            onClick={() => setSelected(interest)}
                            key={interest}
                            className={`p-1 text-center w-fit  cursor-pointer rounded-md font-[500] hover:text-black hover:bg-[#F4F4F4] text-[14px] ${
                              selected === interest
                                ? 'text-black bg-[#F4F4F4]'
                                : 'text-[#4D5861]'
                            }`}
                          >
                            {interest}
                          </span>
                        ))}
                      </Slider>
                    </div>
                    <div
                      onClick={previous}
                      className="w-8 h-8 rounded-full cursor-pointer hover:bg-gray-100 flex items-center justify-center"
                    >
                      <ChevronLeft
                        width={23}
                        height={23}
                        className="cursor-pointer hover:text-black text-[#6B6B6B]"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='w-full'>
              {data?.articles?.length === 0 ? (
                <div className="flex items-center  justify-center gap-4 w-full">
                  <p className="text-[#334155] font-normal text-[14px]">
                    لا يوجد نتائج
                  </p>
                </div>
              ) : (
                <div className="flex flex-col   gap-4 w-full">
                  {data?.articles.map((article) => (
                    <ExploreArticleCard key={article?._id} article={article} />
                  ))}
                </div>
              )}
              </div>
            </div>
            <div className="lg:w-1/3 hidden md:p-8 p-6 lg:flex flex-col gap-3">
              <span className="text-[20px] font-[600]">أشهر المقالات</span>
              {data?.articles.slice(0, 3).map((article) => (
                <div key={article._id} className="flex w-full flex-col gap-3">
                  <span
                   onClick={() =>
                    router.push(`/@${article?.author?.username}`)
                  }
                    className="flex gap-2 cursor-pointer"
                  >
                    {article?.author?.profileImage ? (
                      <Image
                        src={article?.author?.profileImage}
                        alt=""
                        width={30}
                        height={30}
                        className="w-[30px] h-[30px] rounded-full bg-gray-600 text-white flex justify-center items-center"
                      />
                    ) : (
                      <span className="w-[30px] h-[30px] rounded-full bg-gray-600 text-white flex justify-center items-center">
                        {article?.author?.name?.charAt(0) || 'A'}
                      </span>
                    )}
                    <p className="text-[16px] text-[#8641BD] font-[500]">
                      {article?.author?.name || 'Author'}{' '}
                    </p>
                  </span>
                  <p
                     onClick={() =>
                      router.push(`/view-article/${article?._id}`)
                    }
                  className="text-[16px] font-[500] cursor-pointer">{article?.title}</p>
                </div>
              ))}
              <span className="text-[#8641BD] cursor-pointer text-[17px] font-[600]">
                اقرأ المزيد
              </span>
              <div className="bg-[#FAF7FD] p-3 space-y-3 w-[250px] rounded-md">
                <span className="flex items-center justify-between w-full">
                  <p className="text-[16px] font-[700]">مصادر مهمة</p>
                  <IoClose />
                </span>
                <span className="text-[14px] space-y-2 font-[400]">
                  <p className="">
                    دورة بريدية مجانية:
                    <span className="font-[600]">
                      فن الكتابة في العالم الرقمي
                    </span>
                  </p>
                  <p>نصائح الخبراء في الكتابة</p>
                  <p>شاركنا رأيك</p>
                </span>
                <Button className="bg-[#8641BD] text-white w-full">
                  انضم إلى مجتمع مئة يوم من الكتابة
                </Button>
              </div>
              <div className="flex flex-col w-[250px] mt-4 gap-2">
                <p
                  onClick={() => router.push('/explore/topics')}
                  className="text-[16px] cursor-pointer font-[600]"
                >
                  موضوعات أخرى
                </p>
                <div className="flex flex-wrap gap-3">
                  {latestSearches.map((search) => (
                    <span
                      key={search}
                      className="bg-[#FAF7FD] cursor-pointer rounded-xl px-3 py-1"
                    >
                      <p className="text-[14px] font-[500]">{search}</p>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <span
              onClick={() => setOpen(!open)}
              className="cursor-pointer  md:hidden flex fixed bottom-14 "
            >
              <Image src={pen} alt="" width={100} height={100} />
            </span>
            <Modal
              isOpen={open}
              onRequestClose={() => setOpen(false)}
              style={customStyles}
            >
              <div className="w-full  flex flex-col gap-3 p-2">
                <span
                  onClick={() => {
                    router.push('/workshop');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex flex-row-reverse gap-1 items-center text-[#101729] font-[500] text-[20px]`}
                >
                  <PencilRuler width={25} height={25} />
                  ورش الكتابة
                </span>
                {/* <span
                  onClick={() => {
                    router.push('/save-idea');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex gap-1 flex-row-reverse items-center  text-[#101729] font-[500] text-[20px]`}
                >
                  <Lightbulb width={25} height={25} />
                  مدوّنة الأفكار
                </span> */}
                <span
                  onClick={() => {
                    router.push('/text-editor');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex flex-row-reverse gap-1 items-center  text-[#101729] font-[500] text-[20px]`}
                >
                  <SquarePen width={25} height={25} />
                  اكتب
                </span>
              </div>
            </Modal>
            {openTopicModal && (
              <AddTopicModal
                isOpen={openTopicModal}
                onClose={() => setOpenTopicModal(false)}
                interests={interests}
                latestSearches={latestSearches}
                handleTopicToggle={handleTopicToggle}
              />
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default HomePage;

const AddTopicModal = ({
  isOpen,
  onClose,
  interests,
  latestSearches,
  handleTopicToggle,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '90%',
      maxWidth: '500px',
      maxHeight: '550px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'thin',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Add Topic Modal"
    >
      <div className="flex flex-col relative gap-4">
        <span
          onClick={onClose}
          className="absolute cursor-pointer top-2 right-2"
        >
          <IoClose />
        </span>
        <span className="text-[20px] font-[600]  w-full text-center">
          إضافة موضوع
        </span>
        {latestSearches.map((search) => (
          <div
            key={search}
            className="flex w-full  justify-between items-center"
          >
            {interests.includes(search) ? (
              <span
                onClick={() => handleTopicToggle(search)}
                className="bg-white cursor-pointer rounded-full text-[#8641BD] border border-[#8641BD] py-1 px-4"
              >
                إلغاء{' '}
              </span>
            ) : (
              <span
                onClick={() => handleTopicToggle(search)}
                className="bg-[#8641BD] cursor-pointer rounded-full text-white border py-1 px-4"
              >
                المتابعة
              </span>
            )}
            <span className=" w-fit  cursor-pointer rounded-xl px-4 py-1">
              <p className="text-[16px] font-[600]">{search}</p>
            </span>
          </div>
        ))}
      </div>
    </Modal>
  );
};
